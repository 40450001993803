<template>
  <div>
    <div class="ui-type-display-lg mb-3">I miei ordini</div>

    <div class="d-flex justify-content-center" v-if="loading">
      <CSpinner color="info" />
    </div>

    <div v-else-if="orders.length > 0">
      <MarketOrder v-for="order in orders" :order="order" :key="order.id" />

      <CPagination
        v-if="numPages > 1 && !loading"
        :active-page.sync="currentPage"
        :pages="numPages"
        sime="sm"
        responsive
      />
    </div>

    <div v-else>Non ci sono ordini</div>

    <MarketOrderMessageModal />
    <IncidentDetailsModal />
  </div>
</template>

<script>
import { GetDataManagerNew, ApiCall } from "../../ds";
import { Query } from "@syncfusion/ej2-data";
import { mapGetters } from "vuex";
import { MarketOrder, MarketOrderMessageModal } from "./partials";
import IncidentDetailsModal from "../share/IncidentDetailsModal";

export default {
  name: "UiMarketOrders",
  components: {
    MarketOrder,
    MarketOrderMessageModal,
    IncidentDetailsModal,
  },

  data() {
    return {
      loading: false,
      orders: [],
      numPages: 1,
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters("connections", ["familyId"]),
  },

  mounted() {
    this.getOrders();
  },

  watch: {
    currentPage: function () {
      this.getOrders();
    },
  },

  methods: {
    getOrders() {
      const self = this;
      self.loading = true;
      const query = new Query();
      const itemsPerPage = 5;
      query.page(this.currentPage, itemsPerPage);
      ApiCall(
        GetDataManagerNew("role_order_family", [this.familyId]),
        query,
        (response) => {
          self.numPages = Math.ceil(
            response.actual.payload.Count / itemsPerPage
          );
          self.orders = response.result;
          self.loading = false;
        },
        (e) => {
          console.error(e);
          self.loading = false;
        }
      );
    },

    changeZipCode() {},
  },
};
</script>
